import React, { useState, useEffect } from "react";
import JSZip from "jszip";
import DownloadSvg from '../../assets/download.svg';
import { DownloadPedidosButtonContainer } from './styles';
import { ReactSVG } from 'react-svg';
import axios from 'axios';
import api from '../../services/api';
// import { PDFDocument } from 'pdf-lib';

const zipFiles = async (arquivos, setProgress, tipoPedidos) => {
    const zip = new JSZip();
    for (let i = 0; i < arquivos.length; i++) {
        let responseCardapio = '';
        let responseQrCode = '';
        if (tipoPedidos === 'pdfs' && Number(arquivos[i].pedido.impresso)) {
            responseCardapio = await axios.get(`https://gestorcadapios.blob.core.windows.net${arquivos[i].pedido.link_pdv}.pdf`, {
                responseType: "arraybuffer", 
            });
        };
        if (tipoPedidos === 'qrcodes' && Number(arquivos[i].pedido.qrcode)) {
            const fileName = arquivos[i].pedido.link_pdv.split('/').pop();
            responseQrCode = await axios.get(`https://gestorcadapios.blob.core.windows.net/qrcode/${fileName}.pdf`, {
                responseType: "arraybuffer",
            });
        };
      
        if (arquivos[i].pedido.status === 'APROVADO'){
            await api.patch(`/pedidos/${arquivos[i].pedido.id}/status`, { status: 'BAIXADO', leva_id: arquivos[i].leva_id})
        }
        setProgress(i + 1);

        // if (responseCardapio && responseQrCode) {
        //     const pdfDoc1 = await PDFDocument.load(responseCardapio.data);
        //     const pdfDoc2 = await PDFDocument.load(responseQrCode.data);

        //     const mergedDoc = await PDFDocument.create();
        //     for (let i = 0; i < pdfDoc1.getPageCount(); i++) {
        //         const [page] = await mergedDoc.copyPages(pdfDoc1, [i]);
        //         mergedDoc.addPage(page);
        //     };
        //     for (let i = 0; i < pdfDoc2.getPageCount(); i++) {
        //         const [page] = await mergedDoc.copyPages(pdfDoc2, [i]);
        //         mergedDoc.addPage(page);
        //     };

        //     const mergedPdfBytes = await mergedDoc.save();
        //     zip.file(arquivos[i].fileName, mergedPdfBytes);
        // } else 
        if (responseCardapio) {
            zip.file(arquivos[i].fileName, responseCardapio.data);
        } else if (responseQrCode) {
            zip.file(`${arquivos[i].fileName}`, responseQrCode.data);
        };
    };
    const content = await zip.generateAsync({ type: "blob" });
    return content;
};

const DownloadPedidosButton = ({ arquivos, buscaPedidos, tipoPedidos }) => {
    const [arquivosFiltrados, setArquivosFiltrados] = useState([]);
    
    const [progress, setProgress] = useState(0);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (tipoPedidos === 'pdfs') {
            setArquivosFiltrados(arquivos.filter((arquivo) => Number(arquivo.pedido.impresso)));
        } else if (tipoPedidos === 'qrcodes') {
            setArquivosFiltrados(arquivos.filter((arquivo) => Number(arquivo.pedido.qrcode)));
        }
    }, [arquivos, tipoPedidos]);

    useEffect(() => {
        let mounted = true;
        if (!loading) return;
        zipFiles(arquivosFiltrados, setProgress, tipoPedidos)
            .then((content) => {
                if (mounted) {
                    setLoading(false);
                    setProgress(0);
                    const link = document.createElement("a");
                    link.href = URL.createObjectURL(content);
                    if (tipoPedidos === 'pdfs') {
                        link.download = "pedidos.zip";
                    } else if (tipoPedidos === 'qrcodes') {
                        link.download = "qrcodes.zip";
                    };
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    buscaPedidos();
                }
            })
            .catch((error) => {
                setLoading(false);
                setProgress(0);
            });
        return () => {
            mounted = false;
        };
    }, [loading, arquivosFiltrados]);

    return (
        <DownloadPedidosButtonContainer>
            {loading ? (
                <div 
                    className='loading'
                >
                    <div>
                        <span>{progress}</span> de <span>{arquivosFiltrados.length}</span>
                    </div>
                    <progress value={progress} max={arquivosFiltrados.length} style={{width: '110px'}} />
                </div>
            ) : (
                <button className='btn-download' onClick={() => setLoading(true)}>
                    <ReactSVG src={DownloadSvg}/> {tipoPedidos === 'pdfs' ? 'Baixar Pedidos' : 'Baixar QrCodes'}      
                </button>
            )}
        </DownloadPedidosButtonContainer>
    );
}

export default DownloadPedidosButton;